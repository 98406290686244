import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Autocomplete, TextField, Checkbox, ListItemText, MenuItem } from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import { useSelector } from 'react-redux';
import { getProjectsData } from '../../../../../../redux/selectors';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';

const SelectAllAutocomplete = ({ options, handleChange }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const autocompleteRef = useRef(null);

  // Updated options array
  // const options = useSelector(getProjectsData);

  // Filter options based on the search query and sort to show selected items at the end
  const filteredOptions = options
    .filter(
      option =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        selectedItems.includes(option.value)
    )
    .sort((a, b) => {
      const isASelected = selectedItems.includes(a.value);
      const isBSelected = selectedItems.includes(b.value);

      if (searchQuery) {
        if (isASelected && !isBSelected) return 1;
        if (!isASelected && isBSelected) return -1;

        const aMatches = a.label.toLowerCase().includes(searchQuery.toLowerCase());
        const bMatches = b.label.toLowerCase().includes(searchQuery.toLowerCase());

        if (aMatches && !bMatches) return -1;
        if (!aMatches && bMatches) return 1;
      }
      return 0;
    });

  // Handle selection changes
  const handleSelectionChange = (event, value, reason) => {
    handleChange(value);
    setSelectedItems(value);
    if (reason === 'clear') {
      setSearchQuery('');
    }
  };

  // Handle the "Select All" option
  const handleSelectAll = () => {
    if (selectedItems.length === options.length) {
      handleChange([]);
      setSelectedItems([]); // Deselect all if already selected
    } else {
      handleChange(options.map(option => option.value));
      setSelectedItems(options.map(option => option.value)); // Select all options by value
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
    setDisplayValue(searchQuery);
  };

  const handleClose = () => {
    setIsOpen(false);
    setDisplayValue('');
  };

  useEffect(() => {
    if (isOpen) {
      setDisplayValue(searchQuery);
    }
  }, [searchQuery, isOpen]);

  const renderTags = selectedValues => {
    if (selectedValues.length === 0) {
      return <span>{''}</span>;
    } else if (selectedValues.length === 1) {
      const selectedOption = options.find(opt => opt.value === selectedValues[0]);
      return <span style={{ fontSize: '10px' }}>{selectedOption ? selectedOption.label : ''}</span>;
    } else {
      return <span style={{ fontSize: '10px' }}>{'Multiple selections'}</span>;
    }
  };

  // Virtualized List Row Rendering
  const Row = useCallback(
    ({ index, style }) => {
      const option = filteredOptions[index];
      const isSelected = selectedItems.includes(option.value);

      // Handle the selection/deselection within the row
      const handleClick = () => {
        if (isSelected) {
          setSelectedItems(prev => {
            const values = prev.filter(item => item !== option.value);
            handleChange(values);
            return values;
          }); // Deselect the option
        } else {
          setSelectedItems(prev => {
            const values = [...prev, option.value];
            handleChange(values);
            return [...prev, option.value];
          }); // Select the option
        }
      };
      return (
        <MenuItem style={{ ...style, padding: '6px' }} key={option.value} onClick={handleClick}>
          <input type="checkbox" checked={isSelected} onChange={() => {}} className="mr-xxs" />
          <EmwTypography fontSize={8}>{option.label}</EmwTypography>
        </MenuItem>
      );
    },
    [selectedItems, filteredOptions]
  );

  return (
    <Autocomplete
      multiple
      ref={autocompleteRef}
      clearOnBlur={false}
      selectOnFocus={false}
      freeSolo
      value={selectedItems}
      onChange={handleSelectionChange}
      options={filteredOptions}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      disableCloseOnSelect
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(option, value) => option.value === value}
      renderInput={params => (
        <TextField
          {...params}
          value={isOpen ? displayValue : ''}
          onChange={e => {
            setSearchQuery(e.target.value);
            setDisplayValue(e.target.value);
          }}
          inputProps={{ ...params.inputProps, value: isOpen ? displayValue : '' }}
        />
      )}
      renderTags={() => renderTags(selectedItems)}
      sx={{
        '&.MuiAutocomplete-root': {
          '*': {
            border: 0,
          },
          '& .MuiInputBase-root': {
            borderRadius: 0,
            height: '18px',
            background: 'white',
            width: '142px',
            paddingRight: '42px',
            padding: '0px 4px',
            overflow: 'hidden',
            '& .MuiInputBase-input': {
              height: '12px',
              fontSize: '12px',
              borderRadius: 0,
            },
            '& .MuiAutocomplete-endAdornment': {
              marginRight: 0,
              button: {
                padding: 0,
                svg: {
                  width: '16px',
                },
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
          },
        },
      }}
      ListboxComponent={props => (
        <div {...props}>
          <div
            className="flex"
            style={{ padding: '6px', cursor: 'pointer' }}
            onClick={handleSelectAll}
          >
            <input
              type="checkbox"
              checked={selectedItems.length === filteredOptions.length}
              onChange={() => {}}
              className="mr-xxs"
            />
            <EmwTypography fontSize={8}>
              <>{selectedItems.length === options.length ? 'Deselect All' : 'Select All'}</>
            </EmwTypography>
          </div>
          {/* Virtualized List */}
          <List
            height={200} // Height of the virtualized list
            itemCount={filteredOptions.length}
            itemSize={26} // Height of each list item
            width="100%" // Full width
          >
            {Row}
          </List>
        </div>
      )}
    />
  );
};

export default SelectAllAutocomplete;
