import React, { useContext, useEffect, useState } from 'react';
import { computeURL, fetchOptions } from '../../../../../util/fetch';
import { DRILL_REQUESTS } from './components/DrillToDetails/constants';
import { HzaContext } from '../../../hzaSync/context/HzaContext';
import { useDispatch, useSelector } from 'react-redux';
import { getMcdDrillDataPayload } from '../../redux/selectors';
import {
  setDrillResetData,
  setMcdCountriesData,
  setMcdDrillProcessData,
  setMcdDrillProcessFilteredData,
  setMcdDrillToData,
  setMcdProcessDataConfidence,
  setMcdProcessDataConfidenceDistribution,
  setMcdProcessDataConfSilent,
  setMcdProcessDataMood,
  setMcdProcessDataMoodDistribution,
  setMcdProcessDataMoodSilent,
  setProjectsData,
} from '../../redux/actions';
import { CHART_TYPE } from './utils';

const mcdProcessDataApi = computeURL('mcd', DRILL_REQUESTS.MCD.PROCESS_DATA);
const mcdDataApi = computeURL('mcd', DRILL_REQUESTS.MCD.DATA);
const projectsApi = computeURL('mcd', DRILL_REQUESTS.MCD.PROJECTS);
const countriesApi = computeURL('mcd', DRILL_REQUESTS.MCD.COUNTRIES);
const mcdMoodWordsApi = computeURL('mcd', DRILL_REQUESTS.MCD.MOOD_WORDS);
const mcdConfidenceWordsApi = computeURL('mcd', DRILL_REQUESTS.MCD.CONFIDENCE_WORDS);
const mcdProcessDataMoodApi = computeURL('mcd', DRILL_REQUESTS.MCD.PROCESS_DATA.MOOD);
const mcdProcessDataMoodDistApi = computeURL(
  'mcd',
  DRILL_REQUESTS.MCD.PROCESS_DATA.MOOD_DISTRIBUTION
);
const mcdProcessDataConfidenceApi = computeURL('mcd', DRILL_REQUESTS.MCD.PROCESS_DATA.CONFIDENCE);
const mcdProcessDataConfidenceDistApi = computeURL(
  'mcd',
  DRILL_REQUESTS.MCD.PROCESS_DATA.CONFIDENCE_DISTRIBUTION
);

const mcdProcessDataParams = fetchOptions({ method: 'POST' });
const mcdDataParams = fetchOptions({ method: 'POST' });
const projectsParams = fetchOptions({ method: 'POST' });
const countriesParams = fetchOptions({ method: 'POST' });
const mcdMoodWordParams = fetchOptions({ method: 'POST' });
const mcdConfidenceWordParams = fetchOptions({ method: 'POST' });

export default function useRequests() {
  const hzaContext = useContext(HzaContext);
  const reqDataPayload = useSelector(getMcdDrillDataPayload);
  const dispatch = useDispatch();
  const [moodWords, setMoodWords] = useState([]);
  const [confidenceWords, setConfidenceWords] = useState([]);
  const [isFilteredData, setIsFilteredData] = useState(false);

  useEffect(() => {
    const response = hzaContext.mcdDataReqStatus;
    dispatch(setMcdDrillToData(response.data));
    finishRequest(DRILL_REQUESTS.MCD.DATA);
  }, [hzaContext.mcdDataReqStatus]);

  useEffect(() => {
    const response = hzaContext.mcdProcessDataReqStatus;
    if (isFilteredData) {
      dispatch(setMcdDrillProcessFilteredData(response.data));
    } else {
      dispatch(setMcdDrillProcessData(response.data));
    }
  }, [hzaContext.mcdProcessDataReqStatus]);

  useEffect(() => {
    const response = hzaContext.mcdMoodWordsDataReqStatus;
    setMoodWords(response.data);
  }, [hzaContext.mcdMoodWordsDataReqStatus]);

  useEffect(() => {
    const response = hzaContext.mcdConfWordsDataReqStatus;
    setConfidenceWords(response.data);
  }, [hzaContext.mcdConfWordsDataReqStatus]);

  useEffect(() => {
    const response = hzaContext.mcdProjectsDataReqStatus;
    dispatch(
      setProjectsData(
        response.data.map(project => {
          return { label: project, value: project };
        })
      )
    );
  }, [hzaContext.mcdProjectsDataReqStatus]);

  useEffect(() => {
    dispatch(setMcdProcessDataMood(hzaContext.mcdProcessDataMood.data));
  }, [hzaContext.mcdProcessDataMood]);

  useEffect(() => {
    dispatch(setMcdProcessDataMoodDistribution(hzaContext.mcdProcessDataMoodDist.data));
  }, [hzaContext.mcdProcessDataMoodDist]);

  useEffect(() => {
    dispatch(setMcdProcessDataConfidence(hzaContext.mcdProcessDataConf.data));
  }, [hzaContext.mcdProcessDataConf]);

  useEffect(() => {
    dispatch(setMcdProcessDataConfidenceDistribution(hzaContext.mcdProcessDataConfDist.data));
  }, [hzaContext.mcdProcessDataConfDist]);

  useEffect(() => {
    const response = hzaContext.mcdCountries;
    dispatch(
      setMcdCountriesData(
        response.data.map(country => {
          return { label: country, value: country };
        })
      )
    );
  }, [hzaContext.mcdCountries]);

  useEffect(() => {
    dispatch(setMcdProcessDataConfSilent(hzaContext.mcdConfidenceSilenceReq.data));
  }, [hzaContext.mcdConfidenceSilenceReq]);

  useEffect(() => {
    dispatch(setMcdProcessDataMoodSilent(hzaContext.mcdMoodSilenceReq.data));
  }, [hzaContext.mcdMoodSilenceReq]);

  const handleSilentReq = async (payload, type) => {
    const _reqDataPayload = { ...reqDataPayload, ...payload };
    mcdProcessDataParams.body = JSON.stringify(_reqDataPayload);

    if (type === CHART_TYPE.MOOD) {
      makeRequest(
        mcdProcessDataMoodApi,
        mcdProcessDataParams,
        DRILL_REQUESTS.MCD.PROCESS_DATA.MOOD_SILENCE
      );
    }

    if (type === CHART_TYPE.CONFIDENCE) {
      makeRequest(
        mcdProcessDataConfidenceApi,
        mcdProcessDataParams,
        DRILL_REQUESTS.MCD.PROCESS_DATA.CONFIDENCE_SILENCE
      );
    }
  };

  const handleRequests = async (payload, skip = []) => {
    dispatch(setDrillResetData(true));
    const _reqDataPayload = { ...reqDataPayload, ...payload };
    mcdProcessDataParams.body = JSON.stringify(payload);
    mcdMoodWordParams.body = JSON.stringify(payload);
    mcdConfidenceWordParams.body = JSON.stringify(payload);
    mcdDataParams.body = JSON.stringify(_reqDataPayload);
    projectsParams.body = JSON.stringify(_reqDataPayload);
    countriesParams.body = JSON.stringify(_reqDataPayload);

    if (!skip.includes(DRILL_REQUESTS.MCD.DATA)) {
      makeRequest(mcdDataApi, mcdDataParams, DRILL_REQUESTS.MCD.DATA);
    }

    if (!skip.includes(DRILL_REQUESTS.MCD.PROCESS_DATA.MOOD)) {
      makeRequest(
        mcdProcessDataMoodApi,
        mcdProcessDataParams,
        DRILL_REQUESTS.MCD.PROCESS_DATA.MOOD
      );
    }

    if (!skip.includes(DRILL_REQUESTS.MCD.PROCESS_DATA.MOOD_DISTRIBUTION)) {
      makeRequest(
        mcdProcessDataMoodDistApi,
        mcdProcessDataParams,
        DRILL_REQUESTS.MCD.PROCESS_DATA.MOOD_DISTRIBUTION
      );
    }

    if (!skip.includes(DRILL_REQUESTS.MCD.PROCESS_DATA.CONFIDENCE)) {
      makeRequest(
        mcdProcessDataConfidenceApi,
        mcdProcessDataParams,
        DRILL_REQUESTS.MCD.PROCESS_DATA.CONFIDENCE
      );
    }

    if (!skip.includes(DRILL_REQUESTS.MCD.PROCESS_DATA.CONFIDENCE_DISTRIBUTION)) {
      makeRequest(
        mcdProcessDataConfidenceDistApi,
        mcdProcessDataParams,
        DRILL_REQUESTS.MCD.PROCESS_DATA.CONFIDENCE_DISTRIBUTION
      );
    }

    if (!skip.includes(DRILL_REQUESTS.MCD.MOOD_WORDS)) {
      makeRequest(mcdMoodWordsApi, mcdMoodWordParams, DRILL_REQUESTS.MCD.MOOD_WORDS);
    }

    if (!skip.includes(DRILL_REQUESTS.MCD.CONFIDENCE_WORDS)) {
      makeRequest(
        mcdConfidenceWordsApi,
        mcdConfidenceWordParams,
        DRILL_REQUESTS.MCD.CONFIDENCE_WORDS
      );
    }

    if (!skip.includes(DRILL_REQUESTS.MCD.PROJECTS)) {
      makeRequest(projectsApi, projectsParams, DRILL_REQUESTS.MCD.PROJECTS);
    }

    // Countries
    if (!skip.includes(DRILL_REQUESTS.MCD.COUNTRIES)) {
      makeRequest(countriesApi, countriesParams, DRILL_REQUESTS.MCD.COUNTRIES);
    }

    setTimeout(() => {
      dispatch(setDrillResetData(false));
    }, 500);
  };

  const makeRequest = async (api, params, name) => {
    hzaContext.setMcdDataReqStatus({
      ...hzaContext.mcdDataReqStatus,
      isLoading: true,
    });

    hzaContext.setMcdProcessDataMoodDist({
      ...hzaContext.mcdProcessDataMoodDist,
      isLoading: true,
    });

    hzaContext.setMcdProcessDataConfDist({
      ...hzaContext.mcdProcessDataConfDist,
      isLoading: true,
    });

    // hzaContext.setMcdProcessDataReqStatus({
    //   ...hzaContext.mcdProcessDataReqStatus,
    //   isLoading: true,
    // });

    hzaContext.handleRequest(api, params, name);
  };

  const finishRequest = name => {
    // if (name === DRILL_REQUESTS.MCD.PROCESS_DATA) {
    //   hzaContext.setMcdDataReqStatus({
    //     ...hzaContext.mcdDataReqStatus,
    //     isLoading: false,
    //   });
    // }
    //
    //
    // hzaContext.setMcdProcessDataReqStatus({
    //   ...hzaContext.mcdProcessDataReqStatus,
    //   isLoading: true,
    // });
    // dispatch(
    //   setDrillRequestStatus({
    //     ...requestsStatus,
    //     [name]: false,
    //   })
    // );
  };

  return {
    handleRequests,
    moodWords,
    setMoodWords,
    confidenceWords,
    setConfidenceWords,
    isFilteredData,
    setIsFilteredData,
    makeRequest,
    handleSilentReq,
  };
}
