import React, { useEffect, useState, useRef, useMemo } from 'react';
import cloud from 'd3-cloud';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { useDispatch, useSelector } from 'react-redux';
import { getMcdDrillToPayload } from '../../../../features/powerBi/redux/selectors';
import {
  setMcdDrillSelectedWords,
  setMcdDrillToPayload,
} from '../../../../features/powerBi/redux/actions';
import DataStatusFeedback from '../../../../features/powerBi/components/MoodAndConfidence/components/DataStatusFeedback/DataStatusFeedback';
import useRequests from '../../../../features/powerBi/components/MoodAndConfidence/useRequests';
import { setIsFilteredMcdData } from '../../../../features/powerBi/redux/actions';
import cls from 'classnames';
import { Tooltip } from '@mui/material';

/**
 * @TODO: La click pe cuvand trebuie executate requesturile de: mcdProcessData si de
 * @param data
 * @param width
 * @param height
 * @param type
 * @returns {Element}
 * @constructor
 */

export default function EmwWordCloud({ data, width, height, type, showWords, hasNoDataMessage }) {
  const [selectedWords, setSelectedWords] = useState(null);
  const [hoveredWord, setHoveredWord] = useState(null);
  const [layout, setLayout] = useState([]);
  const colorScale = scaleOrdinal(schemeCategory10);
  const cacheRef = useRef(new Map());
  const coloredWords = useMemo(() => {
    return data.map((word, index) => ({ ...word, color: colorScale(index) }));
  }, [data]);
  const mcdReqPayload = useSelector(getMcdDrillToPayload);
  const dispatch = useDispatch();
  const prop = `${type}Words`;
  const { handleRequests } = useRequests();
  const className = cls('emw-word-cloud-text', {
    ['invisible']: !showWords,
  });

  useEffect(() => {
    const cacheKey = JSON.stringify(coloredWords);
    if (cacheRef.current.has(cacheKey)) {
      setLayout(cacheRef.current.get(cacheKey));
    } else {
      cloud()
        .size([width, height])
        .words(
          coloredWords.map(d => ({
            ...d,
            size: calculateWordSize(d.value, data, d),
          }))
        )
        .padding(3)
        .rotate(d => d.value % 1)
        .fontSize(d => d.size)
        .font("'Segoe UI', 'wf_segoe-ui_normal', helvetica, arial, sans-serif")
        .text(d => `${d.text}`)
        .on('end', words => {
          setLayout(words);
          cacheRef.current.set(cacheKey, words);
        })
        .start();
    }
  }, [data]);

  useEffect(() => {
    if (!selectedWords) {
      dispatch(setMcdDrillSelectedWords(selectedWords));
      return;
    }
    if (selectedWords.length > 0) {
      dispatch(setIsFilteredMcdData(true));
      dispatch(setMcdDrillSelectedWords(selectedWords));
    } else {
      dispatch(setIsFilteredMcdData(false));
      dispatch(setMcdDrillSelectedWords(selectedWords));
    }
    const _mcdReqPayload = { ...mcdReqPayload };
    _mcdReqPayload[prop] = selectedWords;
    dispatch(setMcdDrillToPayload(_mcdReqPayload));
    handleRequests(_mcdReqPayload, [prop]);
  }, [selectedWords]);

  const handleWordClick = (event, word) => {
    let _selectedWords = selectedWords || [];

    const isWordSelected = _selectedWords.includes(word.text);

    if (!event.shiftKey) {
      //daca e click normal
      if (_selectedWords.length === 0) {
        //daca nu avem niciun cuvant selectat
        setSelectedWords([word.text]);
      } else if (_selectedWords.length === 1 && isWordSelected) {
        //daca dam click din nou pe acelasi cuvant selectat pt a il deselecta
        setSelectedWords([]);
      } else {
        //daca dam click pe alt cuvant
        setSelectedWords([word.text]);
      }
    } else {
      //daca e shift apasat
      setSelectedWords(prevSelectedWords =>
        isWordSelected
          ? prevSelectedWords.filter(item => item !== word.text)
          : [...prevSelectedWords, word.text]
      );
    }
  };

  const handleMouseLeave = () => {
    setHoveredWord(null);
  };

  const calculateWordSize = (value, words, d) => {
    const maxValue = Math.max(...words.map(w => w.value));
    const minValue = Math.min(...words.map(w => w.value));
    const BASE_MAX_FONT_SIZE = 100;
    const BASE_MIN_FONT_SIZE = 25;
    const BIG_FONT_SIZE = 80;

    const totalWords = words.length;
    const wordCountFactor = Math.max(0.3, Math.min(1, 30 / totalWords));

    const MAX_FONT_SIZE = BASE_MAX_FONT_SIZE * wordCountFactor;
    const MIN_FONT_SIZE = BASE_MIN_FONT_SIZE * wordCountFactor;

    let percentage = (value - minValue) / (maxValue - minValue);
    if (isNaN(percentage)) {
      percentage = 1;
    }

    if (value === maxValue) {
      return BIG_FONT_SIZE;
    }

    percentage = Math.sqrt(percentage);

    const fontSize = MIN_FONT_SIZE + percentage * (MAX_FONT_SIZE - MIN_FONT_SIZE);
    return fontSize;
  };

  if (hasNoDataMessage) {
    return (
      <div className="flex" style={{ height: 300 }}>
        <DataStatusFeedback />
      </div>
    );
  }

  const getOpacity = word => {
    if (!Array.isArray(selectedWords)) {
      return 1;
    }

    return !selectedWords.length || selectedWords.includes(word.text) ? 1 : 0.3;
  };

  return (
    <div className={className}>
      <svg
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMinYMin meet"
        onMouseLeave={handleMouseLeave} // Ensures tooltip disappears only when leaving the word cloud
      >
        <g transform={`translate(${width / 2},${height / 2})`}>
          {layout.map((word, i) => (
            <Tooltip
              followCursor
              key={i}
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 10], // Move the tooltip 10px below the cursor
                      },
                    },
                  ],
                },
                tooltip: {
                  sx: {
                    borderRadius: 0,
                    color: '#ffffff',
                    background: '#333',
                  },
                },
                arrow: {
                  sx: {
                    color: 'rgba(0, 0, 0, 0.8)', // Arrow color
                  },
                },
              }}
              title={
                <div style={{ padding: '4px 0px' }}>
                  {word.text} {'\u00A0\u00A0\u00A0'}
                  {word.value}
                </div>
              }
              className="emw-email"
            >
              <g
                transform={`translate(${word.x}, ${word.y}) rotate(0)`}
                onClick={event => handleWordClick(event, word)}
              >
                <text
                  className="transition-opacity duration-300 cursor-pointer"
                  style={{
                    fontSize: `${word.size}px`,
                    opacity: getOpacity(word),
                    fontFamily: `'Segoe UI', 'wf_segoe-ui_normal', helvetica, arial, sans-serif`,
                    fill: word.color,
                    pointerEvents: 'bounding-box', // Ensures tooltip appears only when hovering over the whole word
                  }}
                  textAnchor="middle"
                >
                  {word.text}
                </text>
              </g>
            </Tooltip>
          ))}
        </g>
      </svg>
    </div>
  );
}
