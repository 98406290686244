import React, { forwardRef, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { useSnackbar, SnackbarContent } from 'notistack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import { EmwButton } from '../../../../../../../lib/common';

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  card: {
    backgroundColor: 'white',
    width: '100%',
    border: '1px solid #B3B0AD',
  },
  typography: {
    fontWeight: 'bold',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
    boxShadow: '10px 5px 5px black',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: 'all .2s',
  },
  collapse: {
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    color: '#b3b3b3',
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}));

const ReportComplete = forwardRef((props, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <EmwTypography fontSize={8}>{props.message}</EmwTypography>
          <div className={classes.icons}>
            <EmwButton variant="ghost" size="small">
              <EmwTypography>X</EmwTypography>
            </EmwButton>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

export default ReportComplete;
