import React, { useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { StyledBarChart } from './styled';
import useChartUtils from '../../hooks/useChartUtils';
import ChartInfo from '../ChartInfo/ChartInfo';
import ChartTooltip from '../ChartTooltip/ChartTooltip';
import EmwButton from '../../../EmwButton/EmwButton';
import EmwTypography from '../../../EmwTypography/EmwTypography';
import ArrowOutlined from '../../../../../lib/icons/ArrowOutlined';
import ArrowOutlinedRight from '../../../../../lib/icons/ArrowOutlinedRight';
import cls from 'classnames';

/**
 * @desc https://nivo.rocks/bar/
 */
export default function BarChart({
  name,
  data,
  info,
  tooltipInfo,
  comingSoon = false,
  unit = '%',
  hasDrillToDetailButton,
  hasDrillThroughButton,
  handleClickDrillThrough,
  handleClickDrillToDetails,
  padding,
  isDrillToDetailsDisabled,
  axisTop,
  axisRight,
  axisBottom,
  axisLeft,
  hasShadow = true,
  margin,
  handleClick,
  isMcdDrillToDetails,
  filteredData,
  isOverlayVisible,
  onBarClick,
  selectedItem,
}) {
  const chartUtils = useChartUtils({});
  const className = cls('flex flex-col bg-white-100 rounded ', {
    'shadow-s-strong relative p-s': hasShadow,
  });
  /**
   * @todo move this outside and add is as prop
   *
   */
  const handleCustomClick = (node, event) => {
    if (isMcdDrillToDetails) {
      onBarClick(node, event);
    }
  };

  return (
    <StyledBarChart className={className} style={{ position: 'relative' }}>
      {name && (
        <div className="flex flex-row justify-between">
          <p className="text-scale-7 font-bold ml-s">{name}</p>
          <ChartTooltip title={tooltipInfo} />
        </div>
      )}
      <div className="flex-1 chart">
        <ResponsiveBar
          data={data}
          keys={['value']}
          indexBy="label"
          margin={margin ? margin : chartUtils.margin}
          padding={padding ? padding : 0.4}
          valueScale={{ type: 'linear' }}
          colors={({ indexValue }) => {
            const opacity = isOverlayVisible && indexValue !== selectedItem ? 0.3 : 1;
            return `rgba(15,	24,	110, ${opacity})`;
          }}
          animate={true}
          enableLabel={true}
          label={d => `${d.value}${unit}`}
          tooltipLabel={tooltip => `${tooltip.data.label}`}
          valueFormat={value => `${value}${unit}`}
          labelTextColor={'white'}
          axisTop={null}
          axisRight={null}
          // axisBottom={axisBottom ? axisBottom : null}
          axisLeft={axisLeft ? axisLeft : chartUtils.axisLeft(unit)}
          indexScale={{ type: 'band', round: true }}
          minValue={0}
          maxValue="auto"
          renderWrapper={true}
          onClick={(node, event) => handleCustomClick(node, event)}
        />
      </div>
      {Boolean(isOverlayVisible && filteredData) && (
        <div
          className="flex-1 chart"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: '1000',
          }}
        >
          <ResponsiveBar
            data={filteredData}
            keys={['value']}
            indexBy="label"
            margin={margin ? margin : chartUtils.margin}
            padding={padding ? padding : 0.4}
            valueScale={{ type: 'linear' }}
            colors="#0F186E"
            animate={true}
            enableLabel={true}
            label={d => `${d.value}${unit}`}
            tooltipLabel={tooltip => `${tooltip.data.label}`}
            valueFormat={value => `${value}${unit}`}
            labelTextColor={'white'}
            axisTop={null}
            axisRight={null}
            axisBottom={axisBottom ? axisBottom : null}
            axisLeft={axisLeft ? axisLeft : chartUtils.axisLeft(unit)}
            indexScale={{ type: 'band', round: true }}
            minValue={0}
            renderWrapper={true}
            onClick={handleCustomClick}
            maxValue={Math.max(...data.map(d => d[['value'][0]]))}
          />
        </div>
      )}

      <ChartInfo info={info} comingSoon={comingSoon} />
      <div className="ml-auto flex flex-row">
        {hasDrillThroughButton && (
          <div className="drill-through-button  mr-xs">
            <EmwButton
              variant="filled"
              size="small"
              title="Analysis across the organization"
              id="drillThrough"
              onClick={handleClickDrillThrough}
              classes={'rounded-md'}
            >
              <EmwTypography classes="mr-xxs">Drill through</EmwTypography>

              <ArrowOutlinedRight />
            </EmwButton>
          </div>
        )}
        {hasDrillToDetailButton && (
          <div className="drill-button">
            <EmwButton
              variant="filled"
              size="small"
              title="Analysis of the source data (access limited to your perimeter)"
              id="drillToDetail"
              onClick={handleClickDrillToDetails}
              classes={'rounded-md'}
              disabled={!isDrillToDetailsDisabled}
            >
              <EmwTypography classes="mr-xxs">Drill to detail</EmwTypography>

              <ArrowOutlined />
            </EmwButton>
          </div>
        )}
      </div>
    </StyledBarChart>
  );
}
