import {
  Box,
  DialogActions,
  DialogContent,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { TdsTypography } from '@tds/react';
import { styled } from '@mui/material/styles';
import { releaseNotes } from './releaseNotes';
import EmwDialog from '../../../../lib/commonv2/EmwDialog';
import EmwButton from '../../../components/EmwButton/EmwButton';
import { useDispatch } from 'react-redux';
import { feedbackInsideModal } from '../../feedback/redux/actions';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: '600px',
}));

const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
  '& .MuiTableCell-root': {
    backgroundColor: '#d8d7d9',
    borderRight: '1px solid #aeafb0',
    borderBottom: '1px solid #aeafb0',
    fontWeight: 'bold',
    color: 'black',
  },
}));
const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  width: '135px',
}));

const StyledTableContentsRow = styled(TableRow)(({ theme }) => ({
  '& .MuiTableCell-root': {
    borderRight: '1px solid #e0dfe0',
  },
}));

const StyledTableContentsCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#d8d7d9',
  fontWeight: 'bold',
  color: 'black',
  borderRight: '1px solid #aeafb0 !important',
  borderBottom: '1px solid #c7c6c8',
}));

const StyledListItem = styled(ListItem)(() => ({
  display: 'list-item',
  padding: 0,
}));

export default function EmwVersionBtn() {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setIsOpen(false);
    dispatch(feedbackInsideModal(false));
  };

  const handleClick = () => {
    setIsOpen(true);
    dispatch(feedbackInsideModal(true));
  };

  const renderTitle = () => {
    return (
      <EmwDialog
        open={isOpen}
        handleClose={handleClose}
        title="EMW Release Note"
        logoOff={true}
        blackTittle={true}
        stretch
        size="md"
      >
        <DialogContent sx={{ minWidth: '100%' }}>
          <Box mt={2} />
          <StyledTableContainer>
            <Table size="small" stickyHeader>
              <TableHead>
                <StyledTableHeadRow>
                  <StyledTableHeadCell>Release Version</StyledTableHeadCell>
                  <StyledTableHeadCell>Release Date</StyledTableHeadCell>
                  <TableCell>Release Description</TableCell>
                </StyledTableHeadRow>
              </TableHead>

              <TableBody>
                {releaseNotes.map(item => (
                  <StyledTableContentsRow>
                    <StyledTableContentsCell>{item.version}</StyledTableContentsCell>
                    <TableCell>{item.date}</TableCell>
                    <TableCell>
                      {item.notes.map(note => {
                        if (note.key !== 'list-item') {
                          return <p>{note.value}</p>;
                        }
                        return <StyledListItem>{note.value}</StyledListItem>;
                      })}
                    </TableCell>
                  </StyledTableContentsRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </DialogContent>
        <DialogActions sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <EmwButton id="btnCloseVersion" variant="filled" onClick={handleClose}>
            Close
          </EmwButton>
        </DialogActions>
      </EmwDialog>
    );
  };

  return (
    <>
      <TdsTypography
        style={{ fontWeight: 500, textDecoration: 'underline' }}
        component="body-2"
        fontWeight="bold"
        className="text-black-500 medium:block emw-version hasTmpZoom"
      >
        <a href="#" onClick={handleClick}>
          EMW Version 1.5
        </a>
      </TdsTypography>
      {isOpen && renderTitle()}
    </>
  );
}
