import React from 'react';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import Stars from '../Stars/Stars';
import { nanoid } from 'nanoid';

export default function DistributionTable({ data, isLoading }) {
  return (
    <table>
      <thead>
        <tr>
          {data.map(column => (
            <td key={column.key}>{column.key}</td>
          ))}
        </tr>
      </thead>
      <tbody style={{ backgroundColor: '#EBF2F8' }}>
        <tr className="leading-3 ">
          {data.map(column => {
            if (['mood', 'confidence'].includes(column.key.toLowerCase())) {
              return (
                <td key={nanoid()}>
                  <Stars value={column.value} size="large" />
                  <EmwTypography fontSize={8}>Average: {column.value}</EmwTypography>
                </td>
              );
            }

            return <td key={nanoid()}>{isLoading ? 0 : column.value}</td>;
          })}
        </tr>
      </tbody>
    </table>
  );
}
