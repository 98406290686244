export const DRILL_REQUESTS = {
  MCD: {
    DATA: 'mcdData',
    PROCESS_DATA: {
      MOOD: 'mcdProcessedData/mood',
      MOOD_DISTRIBUTION: 'mcdProcessedData/moodDistribution',
      MOOD_SILENCE: 'mcdProcessedData/mood?silence',
      CONFIDENCE: 'mcdProcessedData/confidence',
      CONFIDENCE_DISTRIBUTION: 'mcdProcessedData/confidenceDistribution',
      CONFIDENCE_SILENCE: 'mcdProcessedData/confidence?silence',
    },
    CONFIDENCE_WORDS: 'confidenceWords',
    MOOD_WORDS: 'moodWords',
    PROJECTS: 'projects',
    COUNTRIES: 'countries',
  },
};
