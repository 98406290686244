import React from 'react';
import { TdsProgressCircle } from '@tds/react';
import { QtmProgressCircle } from '@qtm/react';

export default function EmwProgressCircle({ color, size, trackOff, classes }) {
  return (
    <QtmProgressCircle
      color={color ? color : 'primary'}
      size={size}
      trackOff={trackOff}
      classes={classes}
    />
  );
  return (
    <TdsProgressCircle
      color={color ? color : 'primary'}
      size={size}
      trackOff={trackOff}
      classes={classes}
    />
  );
}
