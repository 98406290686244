export function transformMoodData(data) {
  const headers = [];
  const rows = data;

  const getName = key => {
    if (key === 'startDate') {
      return 'Date';
    }
    if (key === 'gbuCode') {
      return 'GBU';
    }
    if (key === 'blCode') {
      return 'BL';
    }
    if (key === 'ccName') {
      return 'CC';
    }
    if (key === 'country') {
      return 'Country';
    }
    if (key === 'moodSatisfaction') {
      return 'Mood';
    }
    if (key === 'moodComment') {
      return 'Comment';
    }
    if (key === 'confindenceToDeliverSatisfaction') {
      return 'Confidence';
    }
    if (key === 'confindenceToDeliverComment') {
      return 'Comment';
    }
    if (key === 'pname') {
      return 'Project';
    }
  };

  Object.keys(data[0]).forEach(key => {
    headers.push({ header: getName(key), key: key });
  });

  return {
    headers,
    rows,
  };
}
