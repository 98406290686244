import React, { useEffect, useState } from 'react';
import { abbreviateNumber } from '../../utils';
import { useSelector } from 'react-redux';
import { getMcdDrillCachedCharts } from '../../../../redux/selectors';
import { ResponsiveBar } from '@nivo/bar';
import useChartUtils from '../../../../../../components/EmwCharts/hooks/useChartUtils';
import { StyledDrilledBarChart } from './styled';

export default function DrillChart({
  data,
  type,
  handleBarClick,
  xLegend,
  yLegend,
  isInFilteredMode,
  isFilteredData,
}) {
  const cachedCharts = useSelector(getMcdDrillCachedCharts);
  const chartUtils = useChartUtils({});
  const [chartData, setChartData] = useState(data);
  const unit = '';
  const axisLeft = {
    tickSize: 0,
    tickPadding: -8,
    tickRotation: 0,
    legend: yLegend,
    legendPosition: 'middle',
    legendOffset: -30,
    format: value => abbreviateNumber(value, true),
  };

  const axisBottom = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: xLegend,
    legendPosition: 'middle',
    legendOffset: 25,
    truncateTickAt: 0,
  };

  useEffect(() => {
    if (cachedCharts[type].selectedBars.length) {
      const _chartData = cachedCharts[type];
      setChartData(_chartData.bars);
    } else {
      setChartData(data);
    }
  }, [cachedCharts, data]);

  useEffect(() => {
    setChartData(data);
  }, [data]);

  const handleClick = (barInfo, evt) => {
    return handleBarClick(barInfo, evt, type);
  };

  return (
    <StyledDrilledBarChart
      className={'flex flex-col bg-white-100 rounded'}
      style={{ position: 'relative' }}
    >
      <ResponsiveBar
        data={chartData}
        keys={['value', 'diffValue']}
        indexBy="label"
        margin={{ top: 10, right: 0, bottom: 38, left: 35 }}
        padding={0.4}
        valueScale={{ type: 'linear' }}
        colors={({ id, index }) => {
          if (id === 'value') {
            return '#0F186E';
          }
          return 'rgba(15, 24, 110, 0.3)';
        }}
        colorBy={'index'}
        animate={false}
        enableLabel={true}
        labelPosition="end"
        labelOffset={-13}
        label={d => `${d.value}${unit}`}
        labelTextColor={'white'}
        tooltip={({ id, value, indexValue, data }) => {
          return (
            <div
              style={{
                background: '#333',
                padding: '9px 12px',
                color: '#fff',
                borderRadius: '3px',
                fontSize: '12px',
              }}
            >
              <span>
                {xLegend}: {indexValue}
                <br />
                {yLegend}: {isInFilteredMode ? data.diffValue : data.value}
                <br />
                {isInFilteredMode && data.value ? `Highlighted: ${data.value}` : null}
              </span>
            </div>
          );
        }}
        valueFormat={value => `${value}${unit}`}
        axisTop={null}
        axisRight={null}
        axisBottom={axisBottom ? axisBottom : null}
        axisLeft={axisLeft ? axisLeft : chartUtils.axisLeft(unit)}
        indexScale={{ type: 'band', round: true }}
        minValue={0}
        maxValue="auto"
        onClick={handleClick}
      />
    </StyledDrilledBarChart>
  );
}
