import { useEffect, useState } from 'react';
import { HZA_IFRAME_ID } from '../../../../../hzaSync/constants';
import DrillToDetails from './DrillToDetails';

export default function DrillContainer() {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    checkElement();
  }, []);

  const checkElement = () => {
    const interval = setInterval(() => {
      const hzaIframe = document.getElementById(HZA_IFRAME_ID);

      if (hzaIframe) {
        clearInterval(interval);
        setIframeLoaded(true);
      }
    }, 1000);
  };

  if (iframeLoaded) {
    return <DrillToDetails />;
  }

  return '...';
}
