import React, { Suspense, useEffect, useState } from 'react';
import { Resizable } from 're-resizable';
import { useDispatch, useSelector } from 'react-redux';
import { FILTERS_SECTIONS, RESIZABLE, RESIZABLE_CLOSED } from './constants';
import { StyledEmwGeneralFilters } from './components/styled';
import GeneralFiltersHeader from './components/GeneralFiltersHeader/GeneralFiltersHeader';
import GeneralFiltersWrapper from './components/GeneralFiltersWrapper/GeneralFiltersWrapper';
import GeneralFiltersCard from './components/GeneralFiltersCard/GeneralFiltersCard';
import { useLocation } from 'react-router-dom-v5-compat';
import { treeDataSelector, userSelector } from '../../features/header/redux/selectors';
import useGeneralFilters from './hooks/useGeneralFilters';
import { availableFiltersSelector, selectedGeneralFilters } from './redux/selectors';
import { clearAllFilters } from './redux/actions';
import { generateRandom } from '../../../util/generateRandom';

export default function EmwGeneralFilters({ onResize, param }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(userSelector);
  const [isExpanded, setIsExpanded] = useState(true);
  const { buildFiltersWithParamFromGbus } = useGeneralFilters();
  const treeData = useSelector(treeDataSelector);
  const selectedFilters = useSelector(selectedGeneralFilters);
  const availableFilters = useSelector(availableFiltersSelector);
  const isDrilled =
    window.location.hash.includes('#drilltodetails') ||
    location.pathname.includes('/drill-to-details');

  useEffect(() => {
    return () => {
      dispatch(clearAllFilters(generateRandom()));
    };
  }, []);

  useEffect(() => {
    if (user.list.data && !user.list.data.admin && user.list.data.orgRoles[0]) {
      let userFirstOrg;
      const type =
        (user.list.data.usedRole && user.list.data.usedRole.orgDetails.type) ||
        user.list.data.orgRoles[0].orgDetails.type;
      if (type && type.toLowerCase() !== 'su') {
        userFirstOrg =
          (user.list.data.usedRole && user.list.data.usedRole.orgDetails) ||
          user.list.data.orgRoles[0].orgDetails;
      }
    }
  }, [user]);

  useEffect(() => {
    if (
      treeData.list.hasOwnProperty('data') &&
      selectedFilters.country.length === 0 &&
      selectedFilters.bl.length === 0 &&
      selectedFilters.cc.length === 0 &&
      selectedFilters.gbu.length === 0
    ) {
      if (isDrilled) {
        buildFiltersWithParamFromGbus(treeData.list.data, param);
      } else {
        buildFiltersWithParamFromGbus(treeData.list.data);
      }
    }
  }, [treeData, selectedFilters, isDrilled]);

  const handleToggleFilters = event => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {isExpanded && (
        <Resizable {...RESIZABLE} onResize={onResize}>
          <StyledEmwGeneralFilters>
            <Suspense fallback={'Loading ...'}>
              <GeneralFiltersHeader toggle={handleToggleFilters} isExpanded={isExpanded} />
              <GeneralFiltersWrapper>
                <GeneralFiltersCard
                  type={FILTERS_SECTIONS.COUNTRY}
                  items={availableFilters.country}
                />
                <GeneralFiltersCard type={FILTERS_SECTIONS.GBU} items={availableFilters.gbu} />
                <GeneralFiltersCard type={FILTERS_SECTIONS.BL} items={availableFilters.bl} />
                <GeneralFiltersCard type={FILTERS_SECTIONS.CC} items={availableFilters.cc} />
              </GeneralFiltersWrapper>
            </Suspense>
          </StyledEmwGeneralFilters>
        </Resizable>
      )}
      {!isExpanded && (
        <StyledEmwGeneralFilters {...RESIZABLE_CLOSED}>
          <GeneralFiltersHeader toggle={handleToggleFilters} isExpanded={isExpanded} />
        </StyledEmwGeneralFilters>
      )}
    </>
  );
}
