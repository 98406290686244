import React from 'react';
import EmwGeneralFilters from './EmwGeneralFilters';
import { useSelector } from 'react-redux';
import { loadingSelector } from './redux/selectors';
import EmwBackdrop from '../EmwBackdrop/EmwBackdrop';
import cls from 'classnames';
import { getZoom } from '../../utils/sizeUtils';

export default function GeneralFiltersContainer({ children, pt, param }) {
  const loading = useSelector(loadingSelector);
  const className = cls(
    `flex flex-1 ${pt ? pt : 'pt-xl'}  overflow-hidden mt-xs pl-xxl pr-xs pb-l bg-bluegrey-25`,
    {}
  );
  return (
    <>
      <div className="flex flex-1 justify-between overflow-hidden">
        <div className={className} style={{ zoom: `${getZoom()}%` }}>
          {children}
        </div>
        <div>
          <EmwGeneralFilters param={param} />
        </div>
      </div>
      <EmwBackdrop loading={loading} />
    </>
  );
}
