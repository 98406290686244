import * as actions from './actions';
import { getState, reduceAction } from '../../../../util/reducer';
import initialState from './initialState';
import {
  DONLOADING,
  DOWNLOADING,
  MCD_COUNTRIES_DATA,
  MCD_DRILL_BAR_SELECTED,
  MCD_DRILL_CACHED_CHARTS,
  MCD_DRILL_CHART_DIFF_NAME,
  MCD_DRILL_PROCESS_DATA,
  MCD_DRILL_REQUEST_STATUS,
  MCD_DRILL_SELECTED_ROWS,
  MCD_DRILL_TO_PAYLOAD,
  MCD_DRILL_USER_INTERACTED,
  MCD_PROCESS_DATA_CONF_SILENT,
  MCD_PROCESS_DATA_MOOD_SILENT,
  REPORT_FILTERS,
  REPORT_FILTERS_HZA,
} from './actions';

const reducerConfig = {
  [actions.CHARTS]: reduceAction,
  [actions.ALERTS]: reduceAction,
  [actions.COMMENTS]: reduceAction,
  [actions.CREATE_TARGET]: reduceAction,
  [actions.SHOULD_RENDER_LINES]: reduceAction,
  [actions.GENERAL_FILTERS]: reduceAction,
  [actions.CAN_ADD_TARGETS]: reduceAction,
  [actions.CAN_ADD_ALERTS]: reduceAction,
  [actions.USER_ORGANIZATIONS]: reduceAction,
  [actions.ALERT_HISTORY_MODAL]: reduceAction,
  [actions.MY_COMMENTS_HISTORY_MODAL]: reduceAction,
  [actions.POWER_BI_INITIALIZED]: reduceAction,
  [actions.SELECTED_REPORT]: reduceAction,
  [actions.IS_DRILLED]: reduceAction,
  [actions.COMMENTS_ROLES]: reduceAction,
  [actions.ACCESS_TOKEN]: reduceAction,
  [actions.REPORT_FILTERS]: reduceAction,
  [actions.REPORT_FILTERS_DEFAULT]: reduceAction,
  [actions.REPORT_FILTERS_HZA]: reduceAction,
  [actions.EMBEDDED_URL]: reduceAction,
  [actions.MCD_DRILL_TO_PAYLOAD]: reduceAction,
  [actions.MCD_DRILL_TO_DATA]: reduceAction,
  [actions.MCD_DRILL_PROCESS_DATA]: reduceAction,
  [actions.MCD_DRILL_PROCESS_FILTERED_DATA]: reduceAction,
  [actions.MCD_DRILL_DATA_PAYLOAD]: reduceAction,
  [actions.MCD_DRILL_RESET_DATA]: reduceAction,
  [actions.MCD_PROJECTS_DATA]: reduceAction,
  [actions.MCD_COUNTRIES_DATA]: reduceAction,
  [actions.MCD_DRILL_REQUEST_STATUS]: reduceAction,
  [actions.MCD_DRILL_TO_IS_FILTERED_DATA]: reduceAction,
  [actions.MCD_DRILL_SELECTED_ROWS]: reduceAction,
  [actions.MCD_DRILL_SELECTED_WORDS]: reduceAction,
  [actions.DOWNLOADING]: reduceAction,
  [actions.MCD_PROCESS_DATA_MOOD]: reduceAction,
  [actions.MCD_PROCESS_DATA_MOOD_DISTRIBUTION]: reduceAction,
  [actions.MCD_PROCESS_DATA_CONFIDENCE]: reduceAction,
  [actions.MCD_PROCESS_DATA_CONFIDENCE_DISTRIBUTION]: reduceAction,
  [actions.MCD_DRILL_USER_INTERACTED]: reduceAction,
  [actions.MCD_DRILL_CACHED_CHARTS]: reduceAction,
  [actions.MCD_PROCESS_DATA_MOOD_SILENT]: reduceAction,
  [actions.MCD_PROCESS_DATA_CONF_SILENT]: reduceAction,
};

export default (state = initialState, action = {}) =>
  getState(state, action, initialState, reducerConfig);
