import React, { createContext, useEffect, useState } from 'react';
import { HZA_ACTION_TYPE, HZA_IFRAME_ID } from '../constants';
import { getAllSurveys, uploadEeiFromWebFormsApi } from '../../organization/redux/api';
import useAppSnackbar from '../../../hooks/useAppSnankbar';
import { fetchDownload } from '../../../../util/fetch';
import { exportSurvey } from '../exportSurvey';
import { arrayToXcel, exportFile } from '../../../../util/utils';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../../auth/utils/MsGraphApiCall';
import { hzaIframeSelector } from '../../header/redux/selectors';
import { ORGANIZATION_PERSPECTIVE } from '../../organization/constants';
import { jsonToExcel } from '../../../utils/xcelUtils';
import { DRILL_REQUESTS } from '../../powerBi/components/MoodAndConfidence/components/DrillToDetails/constants';
import { transformMoodData } from '../../powerBi/components/MoodAndConfidence/components/ExportData/utils';

const defaultState = {
  isLoading: false,
  done: false,
  name: '',
  data: [],
};

export const HzaContext = createContext({});
export default function HzaContextProvider({ children }) {
  const snackbar = useAppSnackbar();
  const hzaIframeLoaded = useSelector(hzaIframeSelector);
  const [uploadStatus, setUploadStatus] = useState({
    isLoading: false,
    message: '',
    messageType: '',
    data: '',
  });

  const [downloadStatus, setDownloadStatus] = useState({
    isLoading: false,
    done: false,
    name: '',
  });

  const [requestStatus, setRequestStatus] = useState({
    isLoading: false,
    done: false,
    name: '',
    data: null,
  });

  const [mcdDataReqStatus, setMcdDataReqStatus] = useState({
    isLoading: false,
    done: false,
    name: '',
    data: [],
  });

  const [mcdProcessDataReqStatus, setMcdProcessDataReqStatus] = useState({
    isLoading: false,
    done: false,
    name: '',
    data: [],
  });

  const [mcdMoodWordsDataReqStatus, setMcdMoodWordsDataReqStatus] = useState({
    isLoading: false,
    done: false,
    name: '',
    data: [],
  });

  const [mcdConfWordsDataReqStatus, setMcdConfWordsDataReqStatus] = useState({
    isLoading: false,
    done: false,
    name: '',
    data: [],
  });

  const [mcdProjectsDataReqStatus, setMcdProjectsDataReqStatus] = useState({
    isLoading: false,
    done: false,
    name: '',
    data: [],
  });

  const [mcdProcessDataMood, setMcdProcessDataMood] = useState({ ...defaultState });
  const [mcdProcessDataMoodDist, setMcdProcessDataMoodDist] = useState({ ...defaultState });
  const [mcdProcessDataConf, setMcdProcessDataConf] = useState({ ...defaultState });
  const [mcdProcessDataConfDist, setMcdProcessDataConfDist] = useState({ ...defaultState });
  const [mcdCountries, setMcdCountries] = useState({ ...defaultState });

  const [mcdMoodSilenceReq, setMcdMoodSilenceReq] = useState({ ...defaultState });
  const [mcdConfidenceSilenceReq, setMcdConfidenceSilenceReq] = useState({ ...defaultState });

  useEffect(() => {
    window.addEventListener(
      'message',
      async event => {
        if (event.data.type === HZA_ACTION_TYPE.UPLOAD_REQUEST) {
          uploadResponse(event);
        }
        if (event.data.type === HZA_ACTION_TYPE.DOWNLOAD_REQUEST) {
          downloadResponse(event);
        }
        if (event.data.type === HZA_ACTION_TYPE.REQUEST) {
          const { type, data, name } = event.data;

          if (name === DRILL_REQUESTS.MCD.PROCESS_DATA.MOOD) {
            return setMcdProcessDataMood({
              isLoading: false,
              name,
              data,
            });
          }

          if (name === DRILL_REQUESTS.MCD.PROCESS_DATA.MOOD_DISTRIBUTION) {
            return setMcdProcessDataMoodDist({
              isLoading: false,
              name,
              data,
            });
          }

          if (name === DRILL_REQUESTS.MCD.PROCESS_DATA.CONFIDENCE) {
            return setMcdProcessDataConf({
              isLoading: false,
              name,
              data,
            });
          }

          if (name === DRILL_REQUESTS.MCD.PROCESS_DATA.CONFIDENCE_DISTRIBUTION) {
            return setMcdProcessDataConfDist({
              isLoading: false,
              name,
              data,
            });
          }

          if (name === DRILL_REQUESTS.MCD.DATA) {
            return setMcdDataReqStatus({
              isLoading: false,
              name,
              data,
            });
          }

          if (name === DRILL_REQUESTS.MCD.PROCESS_DATA) {
            return setMcdProcessDataReqStatus({
              isLoading: false,
              name,
              data,
            });
          }

          if (name === DRILL_REQUESTS.MCD.MOOD_WORDS) {
            return setMcdMoodWordsDataReqStatus({
              isLoading: false,
              name,
              data,
            });
          }

          if (name === DRILL_REQUESTS.MCD.CONFIDENCE_WORDS) {
            return setMcdConfWordsDataReqStatus({
              isLoading: false,
              name,
              data,
            });
          }

          if (name === DRILL_REQUESTS.MCD.PROJECTS) {
            return setMcdProjectsDataReqStatus({
              isLoading: false,
              name,
              data,
            });
          }

          if (name === DRILL_REQUESTS.MCD.COUNTRIES) {
            return setMcdCountries({
              isLoading: false,
              name,
              data,
            });
          }

          if (name === DRILL_REQUESTS.MCD.PROCESS_DATA.MOOD_SILENCE) {
            return setMcdMoodSilenceReq({
              isLoading: false,
              name,
              data,
            });
          }

          if (name === DRILL_REQUESTS.MCD.PROCESS_DATA.CONFIDENCE_SILENCE) {
            return setMcdConfidenceSilenceReq({
              isLoading: false,
              name,
              data,
            });
          }
          requestResponse(event);
        }
      },
      false
    );
  }, []);

  const clearStates = () => {
    setUploadStatus({
      isLoading: false,
      message: '',
      messageType: '',
      data: '',
    });

    setDownloadStatus({
      isLoading: false,
      done: false,
      name: '',
    });
  };

  const handleRequest = async (api, params, name, skipHza = false, skipFeedback = false) => {
    const accessToken = await getAccessToken();
    const hzaIframe = document.getElementById(HZA_IFRAME_ID).contentWindow;
    const regExp = /^mcd(.*)$/;

    if (!skipFeedback) {
      setRequestStatus({
        isLoading: true,
        done: false,
        name: '',
      });
    }

    const message = {
      type: HZA_ACTION_TYPE.REQUEST,
      name,
      api,
      params: {
        ...params,
        headers: {
          ...params.headers,
          Authorization: 'Bearer '.concat(accessToken),
        },
      },
    };

    if (skipHza) {
      return downloadRequest(message);
    }

    if (hzaIframeLoaded) {
      hzaIframe.postMessage(message, '*');
    } else {
      if (!regExp.test(name)) {
        downloadRequest(message);
      }
    }
  };

  /**
   * Send upload req message to the hza iframe
   */
  const uploadFile = async (
    file,
    api,
    params,
    data,
    name,
    skipHza = false,
    skipFeedback = false
  ) => {
    const accessToken = await getAccessToken();
    const hzaIframe = document.getElementById(HZA_IFRAME_ID).contentWindow;

    if (!skipFeedback) {
      setUploadStatus({
        isLoading: true,
        message: '',
        messageType: '',
        data: '',
      });
    }

    const message = {
      type: HZA_ACTION_TYPE.UPLOAD_REQUEST,
      file,
      api,
      data,
      params: {
        ...params,
        headers: {
          ...params.headers,
          'Content-Type': 'application/json',
          Authorization: 'Bearer '.concat(accessToken),
        },
      },
      name,
    };

    if (skipHza) {
      return uploadRequest(message);
    }

    if (hzaIframeLoaded) {
      hzaIframe.postMessage(message, '*');
    } else {
      uploadRequest(message);
    }
  };

  /**
   * Send download req message to the hza iframe
   */
  const downloadFile = async (fileName, api, params, name, skipHza = false, perspective) => {
    const accessToken = await getAccessToken();
    const hzaIframe = document.getElementById(HZA_IFRAME_ID).contentWindow;
    setDownloadStatus({
      isLoading: true,
      done: false,
      name,
    });

    const message = {
      type: HZA_ACTION_TYPE.DOWNLOAD_REQUEST,
      perspective,
      name,
      fileName,
      api,
      params: {
        ...params,
        headers: {
          ...params.headers,
          Authorization: 'Bearer '.concat(accessToken),
        },
      },
    };

    if (skipHza) {
      return downloadRequest(message);
    }

    if (hzaIframeLoaded) {
      hzaIframe.postMessage(message, '*');
    } else {
      downloadRequest(message);
    }
  };

  const uploadRequest = message => {
    const { file, api, params, data, name } = message;

    if (api.includes('uploadWfbFromWebForms')) {
      params.body = data;
      return fetch(api, params)
        .then(response => response.json())
        .then(response => {
          window.top.postMessage(
            {
              type: 'HZA_UPLOAD_REQUEST',
              ...response,
            },
            '*'
          );
        })
        .catch(error => console.log('error', error));
    }

    uploadEeiFromWebFormsApi(data)
      .then(response => handleSuccess(name, response))
      .catch(reason => handleError(reason))
      .finally(() => handleFinish(name));
  };

  const downloadRequest = message => {
    const { fileName, api, params, name } = message;

    if (api.includes('getWfbDataForOrganization')) {
      return fetch(api, params)
        .then(response => response.json())
        .then(response => {
          window.top.postMessage(
            {
              type: 'HZA_REQUEST',
              name,
              data: response.data,
            },
            '*'
          );
        })
        .catch(error => console.log('error', error));
    }

    if (api.includes('exportEngineeringKpiXls')) {
      return fetch(api, params)
        .then(response => response.json())
        .then(response => {
          window.top.postMessage(
            {
              type: 'HZA_DOWNLOAD_REQUEST',
              name,
              data: response.data,
              fileName: fileName,
            },
            '*'
          );
        })
        .catch(error => console.log('error', error));
    }

    if (api.includes('downloadWfbWithErrorsForm')) {
      return fetch(api, params)
        .then(response => response.json())
        .then(response => {
          window.top.postMessage(
            {
              type: 'HZA_REQUEST',
              name,
              data: response.data,
            },
            '*'
          );
        })
        .catch(error => console.log('error', error));
    }

    if (name === 'export_survey') {
      return getAllSurveys()
        .then(async response => {
          try {
            await exportSurvey(fileName, response.data);
            handleSuccess(name);
          } catch (e) {
            snackbar.show(e.message, 'error');
          }
        })
        .catch(reason => handleError(reason))
        .finally(() => handleFinish(name));
    } else {
      fetchDownload(api, params).then(response => {
        response
          .arrayBuffer()
          .then(buffer => {
            exportFile(fileName, buffer);
            handleSuccess(name);
          })
          .catch(reason => handleError(reason))
          .finally(() => handleFinish(name));
      });
    }
  };

  const uploadResponse = event => {
    setUploadStatus({
      isLoading: false,
      ...event.data,
    });
  };

  const downloadResponse = async event => {
    const { fileName, data, name, perspective } = event.data;

    if (name === 'export_survey') {
      await exportSurvey(fileName, data);
    } else if (name === 'eei_download' && perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
      await jsonToExcel(fileName, data);
    } else if (name === 'opsCockpit_download') {
      await jsonToExcel(fileName, data);
    } else if (name === 'download_mcd_data') {
      await arrayToXcel(fileName, transformMoodData(data));
    } else {
      exportFile(fileName, data);
    }

    return setDownloadStatus({
      isLoading: false,
      downloaded: true,
      name,
    });
  };

  const requestResponse = async event => {
    const { type, data, name } = event.data;

    //TODO: add cases for future requests
    return setRequestStatus({
      isLoading: false,
      name,
      data,
    });
  };

  const handleSuccess = (name, response) => {
    if (name === 'uploadEEI') {
      return setUploadStatus({
        isLoading: false,
        ...response,
      });
    }
    return setDownloadStatus({
      isLoading: false,
      downloaded: true,
      name,
    });
  };

  const handleError = reason => {
    let message;
    if (typeof reason === 'string') {
      message = reason;
    } else {
      message = reason.statusText;
    }
    snackbar.show(message, 'error');
  };

  /*
   * @deprecated
   */
  const handleFinish = name => {
    // if(name === "eei_download"){
    //   return;
    // }
    // setUploadStatus(currentStatus => ({
    //   isLoading: false,
    //   ...currentStatus,
    // }));
  };

  return (
    <HzaContext.Provider
      value={{
        handleRequest,
        uploadFile,
        downloadFile,
        clearStates,
        uploadStatus,
        downloadStatus,
        requestStatus,
        mcdDataReqStatus,
        mcdProcessDataReqStatus,
        mcdMoodWordsDataReqStatus,
        mcdConfWordsDataReqStatus,
        mcdProjectsDataReqStatus,
        setMcdDataReqStatus,
        setMcdProcessDataReqStatus,
        setMcdMoodSilenceReq,
        setMcdConfidenceSilenceReq,
        setMcdProcessDataMoodDist,
        setMcdProcessDataConfDist,
        //

        mcdProcessDataMood,
        mcdProcessDataMoodDist,
        mcdProcessDataConf,
        mcdProcessDataConfDist,
        mcdCountries,
        mcdMoodSilenceReq,
        mcdConfidenceSilenceReq,
      }}
    >
      {children}
    </HzaContext.Provider>
  );
}
