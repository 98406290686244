import React, { useContext, useEffect, useState } from 'react';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import { CHART_TYPE } from '../../utils';
import DistributionTable from '../DistributionTable/DistributionTable';
import EmwWordCloud from '../../../../../../components/EmwCharts/components/EmwWordCount/EmwWordCloud';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import { HzaContext } from '../../../../../hzaSync/context/HzaContext';
import DrillChart from '../DrillChart/DrillChart';

export default function MoodAndDistribution({
  chartData,
  distData,
  words,
  handleClick,
  handleBarClick,
  showWords,
  hasNoDataMessage,
  isInFilteredMode,
  isFilteredData,
}) {
  const hzaContext = useContext(HzaContext);

  return (
    <div className="flex bg-white-100 mood-and-distribution flex-1 relative">
      <LoadingContainer isLoading={hzaContext.mcdProcessDataMoodDist.isLoading} />
      <div className="flex flex-col flex-1">
        <EmwTypography classes="py-xxs pl-xxs" fontWeight="bold">
          Mood and distribution
        </EmwTypography>
        <DistributionTable
          data={distData}
          isLoading={hzaContext.mcdProcessDataMoodDist.isLoading}
        />
        <div className="chart-section flex flex-1 pl-s">
          <DrillChart
            data={chartData}
            type={CHART_TYPE.MOOD}
            handleBarClick={handleBarClick}
            xLegend={CHART_TYPE.MOOD_X_LEGEND}
            yLegend={CHART_TYPE.MOOD_Y_LEGEND}
            isInFilteredMode={isInFilteredMode}
            isFilteredData={isFilteredData}
          />
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <EmwTypography classes="py-xxs pl-xxs" fontWeight="bold">
          Comment
        </EmwTypography>
        <div className="flex-1 overflow-hidden p-xs">
          <EmwWordCloud
            showWords={showWords}
            data={words}
            height={500}
            width={700}
            type={'mood'}
            handleClick={handleClick}
            hasNoDataMessage={hasNoDataMessage}
          />
        </div>
      </div>
    </div>
  );
}
