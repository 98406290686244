import moment from 'moment/moment';

export const CHART_TYPE = {
  MOOD: 'MOOD',
  CONFIDENCE: 'CONFIDENCE',
  MOOD_X_LEGEND: 'Mood',
  MOOD_Y_LEGEND: 'Mood Number of Marks',
  CONFIDENCE_X_LEGEND: 'Confidence to deliver',
  CONFIDENCE_Y_LEGEND: 'Confidence Number of Marks',
};

const DATE_FORMAT = 'YYYY-MM-DD';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function abbreviateNumber(num) {
  if (num < 10000) {
    return num.toString();
  }
  if (isNaN(num)) {
    return 0;
  }

  let transformed = (num / 1000).toString();
  let [integerPart, decimalPart] = transformed.split('.');

  if (decimalPart) {
    decimalPart = decimalPart.substring(0, 2); // Limit to two decimal places without rounding
    transformed = `${integerPart}.${decimalPart}`;
  } else {
    transformed = `${integerPart}.00`; // If no decimal part, add .00
  }

  transformed += 'K';

  return transformed;
}

export function transformChartData(chartData, type) {
  const isMood = type === CHART_TYPE.MOOD;
  const prefix = isMood ? 'm' : 'c';

  return [1, 2, 3, 4, 5].map(num => ({
    label: num,
    value: chartData[`${prefix}${num}`],
  }));
}

export function transformDistData(distData, type) {
  const isMood = type === CHART_TYPE.MOOD;
  const avgKey = isMood ? 'moodavg' : 'confidenceavg';
  const commentsKey = isMood ? 'moodcoments' : 'confidencecoments';
  const distributionLabel = isMood ? 'Mood' : 'Confidence';

  return [
    { key: distributionLabel, value: distData[avgKey] },
    { key: 'Answers', value: abbreviateNumber(distData.total) },
    { key: 'Comments', value: abbreviateNumber(distData[commentsKey]) },
  ];
}

export function transformProcessedData(processedData, type) {
  const isMood = type === CHART_TYPE.MOOD;

  const avgKey = isMood ? 'moodavg' : 'confidenceavg';
  const commentsKey = isMood ? 'moodcoments' : 'confidencecoments';
  const distributionLabel = isMood ? 'Mood' : 'Confidence';
  const prefix = isMood ? 'm' : 'c';

  return {
    distribution: [
      { key: distributionLabel, value: processedData[avgKey] },
      { key: 'Answers', value: abbreviateNumber(processedData.total) },
      { key: 'Comments', value: processedData[commentsKey] },
    ],
    chart: [1, 2, 3, 4, 5].map(num => ({
      label: num,
      value: processedData[`${prefix}${num}`],
    })),
  };
}

export const getPeriodList = () => {
  return [
    { key: '2', value: '2 months', active: false },
    { key: '4', value: '4 months', active: false },
    { key: '6', value: '6 months', active: false },
    { key: '12', value: '12 months', active: false },
    { key: '24', value: '24 months', active: false },
  ];
};

function getRange(year, startMonth, endMonth) {
  const startIndex = months.indexOf(startMonth);
  const endIndex = months.indexOf(endMonth);

  if (startIndex === -1 || endIndex === -1) {
    return 'Invalid month input';
  }

  // Create Date objects for the first and last days
  const start = new Date(year, startIndex, 1); // year doesn't matter
  const end = new Date(year, endIndex + 1, 0); // the 0th day of the next month gives the last day of the current month

  const startDate = moment(start).format(DATE_FORMAT);
  const endDate = moment(end).format(DATE_FORMAT);

  return {
    startDate,
    endDate,
  };
}

export function getRangeList() {
  const monthsRages = [];
  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();

  for (let year = 2011; year <= currentYear; year++) {
    const yearSuffix = String(year).slice(-2);
    for (let i = 0; i < 12; i += 2) {
      if (year === currentYear && i > currentMonthIndex) {
        continue;
      }
      const { startDate, endDate } = getRange(year, months[i], months[i + 1]);
      const value = `${months[i]}-${months[i + 1]} ${yearSuffix}`;

      const range = {
        label: value,
        value,
        startDate,
        endDate,
      };

      monthsRages.push(range);
    }
  }

  const list = monthsRages.reverse();
  list[0].label = 'Current Date';
  return list;
}

export function getDateMinus(date, numberOfMonths) {
  return moment(date).subtract(numberOfMonths, 'month').format('YYYY-MM-DD');
}

export function getTableRows(tableData) {
  return tableData.filter(row => row.length).length;
}
