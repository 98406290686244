import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Grid } from '@mui/material';
import GeneralFiltersContainer from '../../../../../../components/EmwGeneralFilters/GeneralFiltersContainer';
import { DrillToDetailsStyled } from './styled';
import EmwButton from '../../../../../../components/EmwButton/EmwButton';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import MoodAndDistribution from '../MoodDistribution/MoodAndDistribution';
import ConfidenceToDeliverDistribution from '../ConfidenceToDeliverDistribution/ConfidenceToDeliverDistribution';
import DetailsTable from '../DetailsTable/DetailsTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsFilteredMcdData,
  getMcdDrillCachedCharts,
  getMcdDrillDataPayload,
  getMcdDrillProcessData,
  getMcdDrillProcessFilteredData,
  getMcdDrillSelectedRows,
  getMcdDrillToData,
  getMcdDrillToPayload,
  getMcdProcessDataConfidence,
  getMcdProcessDataConfidenceDistribution,
  getMcdProcessDataConfSilent,
  getMcdProcessDataMood,
  getMcdProcessDataMoodDistribution,
  getMcdProcessDataMoodSilent,
  getMcdDrillSelectedWords,
} from '../../../../redux/selectors';
import {
  setMcdDrillCachedCharts,
  setMcdDrillDataPayload,
  setMcdDrillSelectedRows,
  setMcdDrillToPayload,
  setMcdDrillUserInteracted,
} from '../../../../redux/actions';
import {
  CHART_TYPE,
  transformChartData,
  transformDistData,
  transformProcessedData,
} from '../../utils';
import { selectedGeneralFilters } from '../../../../../../components/EmwGeneralFilters/redux/selectors';
import DatePeriod from '../DatePeriod/DatePeriod';
import useGeneralFilters from '../../../../../../components/EmwGeneralFilters/hooks/useGeneralFilters';
import { treeDataSelector } from '../../../../../header/redux/selectors';
import { DRILL_REQUESTS } from './constants';
import useRequests from '../../useRequests';
import { setIsHza } from '../../../../../footer/redux/actions';
import ExportData from '../ExportData/ExportData';
import { defaultDrillDataPayload, defaultDrillToDataPayload } from '../../../../redux/initialState';
import _ from 'lodash';
import { MOOD_AND_CONFIDENCE } from '../../../../constants';

export default function DrillToDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reqDataPayload = useSelector(getMcdDrillDataPayload);
  const { handleRequests, handleSilentReq, moodWords, confidenceWords } = useRequests();
  const moodSilentData = useSelector(getMcdProcessDataMoodSilent);
  const confSilentData = useSelector(getMcdProcessDataConfSilent);
  const { buildFiltersWithParamFromGbus } = useGeneralFilters();
  const cachedCharts = useSelector(getMcdDrillCachedCharts);
  const reqPayload = useSelector(getMcdDrillToPayload);
  const processData = useSelector(getMcdDrillProcessData);
  const moodData = useSelector(getMcdProcessDataMood);
  const moodDistData = useSelector(getMcdProcessDataMoodDistribution);
  const confData = useSelector(getMcdProcessDataConfidence);
  const confDistData = useSelector(getMcdProcessDataConfidenceDistribution);
  const processFilteredData = useSelector(getMcdDrillProcessFilteredData);
  const selectedFilters = useSelector(selectedGeneralFilters);
  const mcdData = useSelector(getMcdDrillToData);
  const treeData = useSelector(treeDataSelector);
  const tableWrapperRef = useRef(null);
  const selectedRows = useSelector(getMcdDrillSelectedRows);
  const selectedWords = useSelector(getMcdDrillSelectedWords);
  const [mood, setMood] = useState(transformProcessedData(processData, CHART_TYPE.MOOD));
  const isFilteredData = useSelector(getIsFilteredMcdData);
  const [confidence, setConfidence] = useState(
    transformProcessedData(processData, CHART_TYPE.CONFIDENCE)
  );
  const [filteredMood, setFilteredMood] = useState(
    transformProcessedData(processFilteredData, CHART_TYPE.MOOD)
  );
  const [filteredConfidence, setFilteredConfidence] = useState(
    transformProcessedData(processFilteredData, CHART_TYPE.CONFIDENCE)
  );
  const [selectedMoodBars, setSelectedMoodBars] = useState(null);
  const [selectedConfBars, setSelectedConfBars] = useState(null);
  const [selectedBar, setSelectedBar] = useState(null);
  const [selectedBars, setSelectedBars] = useState([]);

  const [chartType, setChartType] = useState(null);

  const [loadingState, setLoadingState] = useState({
    [DRILL_REQUESTS.MCD.DATA]: false,
  });

  const [moodChart, setMoodChart] = useState([]);
  const [moodDistribution, setMoodDistribution] = useState([]);
  const [confChart, setConfChart] = useState([]);
  const [confDistribution, setConfDistribution] = useState([]);
  const [hasNoDataMessage, setHasNoDataMessage] = useState(false);
  const [isInFilteredMode, setIsInFilteredMode] = useState(false);

  useEffect(() => {
    return () => {
      clearState();
    };
  }, []);

  const clearState = () => {
    dispatch(setMcdDrillToPayload(defaultDrillToDataPayload));
    dispatch(setMcdDrillDataPayload(defaultDrillDataPayload));
  };

  const checkIfChartHasData = barData => {
    return barData.some(item => item.value !== 0);
  };

  useEffect(() => {
    if (selectedRows.length) {
      dispatch(setMcdDrillUserInteracted(true));
    } else {
      dispatch(setMcdDrillUserInteracted(false));
    }
  }, [selectedRows]);

  // useEffect(() => {
  //   if (selectedWords && selectedWords.length) {
  //     const _cachedCharts = { ...cachedCharts };
  //     _cachedCharts[CHART_TYPE.MOOD].bars = _cachedCharts[CHART_TYPE.MOOD].bars.map(bar => {
  //       bar.diffValue = bar.value;
  //       bar.value = moodData[`m${bar.label}`];

  //       return bar;
  //     });
  //     setMoodChart(_cachedCharts[CHART_TYPE.MOOD].bars);

  //     _cachedCharts[CHART_TYPE.CONFIDENCE].bars = _cachedCharts[CHART_TYPE.CONFIDENCE].bars.map(
  //       bar => {
  //         bar.diffValue = bar.value;
  //         bar.value = confData[`c${bar.label}`];

  //         return bar;
  //       }
  //     );
  //     setConfChart(_cachedCharts[CHART_TYPE.CONFIDENCE].bars);

  //     dispatch(setMcdDrillCachedCharts(_cachedCharts));
  //   }
  // }, [selectedWords, moodData, confData]);

  useEffect(() => {
    // if (!selectedWords || selectedWords.length === 0) {
    setMoodChart(transformChartData(moodData, CHART_TYPE.MOOD));
    // }
  }, [moodData]);

  useEffect(() => {
    setMoodDistribution(transformDistData(moodDistData, CHART_TYPE.MOOD));
  }, [moodDistData]);

  useEffect(() => {
    // if (!selectedWords || selectedWords.length === 0) {
    setConfChart(transformChartData(confData, CHART_TYPE.CONFIDENCE));
    // }
  }, [confData]);

  useEffect(() => {
    setConfDistribution(transformDistData(confDistData, CHART_TYPE.CONFIDENCE));
  }, [confDistData]);

  useEffect(() => {
    const isAnySelection =
      selectedRows?.length ||
      // selectedWords?.length ||
      selectedMoodBars?.length ||
      selectedConfBars?.length;

    setIsInFilteredMode(isAnySelection);
  }, [selectedRows, selectedMoodBars, selectedConfBars /*, selectedWords*/]);

  useEffect(() => {
    if (confChart && moodChart && mcdData) {
      const confChartHasData = checkIfChartHasData(confChart);
      const moodChartHasData = checkIfChartHasData(moodChart);
      const hasBarData = confChartHasData || moodChartHasData;
      if (!hasBarData && !mcdData.length) {
        setHasNoDataMessage(true);
      } else {
        setHasNoDataMessage(false);
      }
    }
  }, [confChart, moodChart, mcdData]);

  useEffect(() => {
    if (selectedRows.length) {
      let moodAvg = 0;
      let confAvg = 0;
      let moodComments = 0;
      let confComments = 0;

      const _moodDistribution = [...moodDistribution];
      const _confDistribution = [...confDistribution];
      let _moodChart = [...moodChart].map(bar => {
        bar.value = 0;
        return bar;
      });
      let _confChart = [...confChart].map(bar => {
        bar.value = 0;
        return bar;
      });
      let __moodChart = transformChartData(moodData, CHART_TYPE.MOOD);
      let __confChart = transformChartData(moodData, CHART_TYPE.CONFIDENCE);

      selectedRows.forEach(row => {
        moodAvg += row.moodSatisfaction;
        confAvg += row.confindenceToDeliverSatisfaction;
        if (row.moodComment) {
          moodComments += 1;
        }
        if (row.confindenceToDeliverComment) {
          confComments += 1;
        }
        _moodChart = _moodChart.map((bar, index) => {
          bar.diffValue = __moodChart[index].value;

          if (bar.label === row.moodSatisfaction) {
            bar.value += 1;
          }

          return bar;
        });
        _confChart = _confChart.map((bar, index) => {
          bar.diffValue = __confChart[index].value;

          if (bar.label === row.confindenceToDeliverSatisfaction) {
            bar.value += 1;
          }

          return bar;
        });
      });
      if (selectedRows.length > 1) {
        moodAvg = (moodAvg / selectedRows.length).toFixed(2);
        confAvg = (confAvg / selectedRows.length).toFixed(2);
      }
      _moodDistribution[0].value = moodAvg;
      _moodDistribution[1].value = selectedRows.length;
      _moodDistribution[2].value = moodComments;

      _confDistribution[0].value = confAvg;
      _confDistribution[1].value = selectedRows.length;
      _confDistribution[2].value = confComments;

      setMoodDistribution(_moodDistribution);
      setConfDistribution(_confDistribution);

      setMoodChart(_moodChart);
      setConfChart(_confChart);
    } else {
      setMoodDistribution(transformDistData(moodDistData, CHART_TYPE.MOOD));
      setConfDistribution(transformDistData(confDistData, CHART_TYPE.CONFIDENCE));
      setMoodChart(transformChartData(moodData, CHART_TYPE.MOOD));
      setConfChart(transformChartData(confData, CHART_TYPE.CONFIDENCE));
    }
  }, [selectedRows]);

  useEffect(() => {
    if (treeData.list.hasOwnProperty('data') && treeData.list.data.regions.length) {
      buildFiltersWithParamFromGbus(treeData.list.data, 'showInDrillToDetails');
    }
  }, [treeData.list.data]);

  useEffect(() => {
    const _payload = { ...reqPayload };
    _payload.mood = [];
    _payload.confidence = [];
    _payload.organizationFilters.countries = selectedFilters.country.map(country => country.id);
    _payload.organizationFilters.gbus = selectedFilters.gbu.map(gbu => gbu.id);
    _payload.organizationFilters.bls = selectedFilters.bl.map(bl => bl.id);
    _payload.organizationFilters.ccs = selectedFilters.cc.map(cc => cc.id);
    dispatch(setMcdDrillToPayload(_payload));
    dispatch(setMcdDrillSelectedRows([]));
    handleRequests(_payload);
  }, [selectedFilters]);

  const handleBack = async () => {
    dispatch(setIsHza(false));
    dispatch(setMcdDrillSelectedRows([]));
    navigate('/moodandconfidence');

    await window.report[MOOD_AND_CONFIDENCE].setPage('ReportSection6251a23cd0ba370500ea');
  };

  const handleClick = value => {
    console.log('handleClick', value);
  };

  useEffect(() => {
    if (_.isObject(confSilentData) && !Array.isArray(confSilentData)) {
      const _cachedCharts = { ...cachedCharts };
      _cachedCharts[CHART_TYPE.CONFIDENCE].bars = _cachedCharts[CHART_TYPE.CONFIDENCE].bars.map(
        bar => {
          if (_cachedCharts[CHART_TYPE.MOOD].selectedBars.length) {
            setSelectedMoodBars(_cachedCharts[CHART_TYPE.MOOD].selectedBars);
            bar.diffValue = bar.value;
            bar.value = confSilentData[`c${bar.label}`];
          } else {
            bar.diffValue = null;
            bar.value = confSilentData[`c${bar.label}`];
          }

          return bar;
        }
      );
      setConfChart(_cachedCharts[CHART_TYPE.CONFIDENCE].bars);

      dispatch(setMcdDrillCachedCharts(_cachedCharts));
    }
  }, [confSilentData]);

  useEffect(() => {
    if (_.isObject(moodSilentData) && !Array.isArray(moodSilentData)) {
      const _cachedCharts = { ...cachedCharts };
      _cachedCharts[CHART_TYPE.MOOD].bars = _cachedCharts[CHART_TYPE.MOOD].bars.map(bar => {
        if (_cachedCharts[CHART_TYPE.CONFIDENCE].selectedBars.length) {
          setSelectedConfBars(_cachedCharts[CHART_TYPE.CONFIDENCE].selectedBars);
          bar.diffValue = bar.value;
          bar.value = moodSilentData[`m${bar.label}`];
        } else {
          bar.diffValue = null;
          bar.value = moodSilentData[`m${bar.label}`];
        }

        return bar;
      });
      setMoodChart(_cachedCharts[CHART_TYPE.MOOD].bars);

      dispatch(setMcdDrillCachedCharts(_cachedCharts));
    }
  }, [moodSilentData]);

  const handleBarClick = (event, node, type) => {
    let _cachedCharts = { ...cachedCharts };
    let __moodChart = transformChartData(moodData, CHART_TYPE.MOOD);
    let __confChart = transformChartData(moodData, CHART_TYPE.CONFIDENCE);
    const targetedChart = type === CHART_TYPE.MOOD ? __moodChart : __confChart;
    let skipRequests = [
      DRILL_REQUESTS.MCD.PROCESS_DATA.MOOD,
      DRILL_REQUESTS.MCD.PROCESS_DATA.CONFIDENCE,
    ];

    const isBarSelected = cachedCharts[type].selectedBars.find(bar => {
      return bar.label === node.indexValue;
    });

    if (event.shiftKey) {
      if (isBarSelected) {
        _cachedCharts[type].selectedBars = _cachedCharts[type].selectedBars.filter(bar => {
          return bar.label !== node.indexValue;
        });
      } else {
        _cachedCharts[type].selectedBars.push({ label: node.indexValue, value: node.value });
      }
    } else {
      if (!isBarSelected) {
        _cachedCharts[type].selectedBars = [{ label: node.indexValue, value: node.value }];
      } else {
        _cachedCharts[type].selectedBars = _cachedCharts[type].selectedBars.filter(bar => {
          return bar.label !== node.indexValue;
        });
      }
    }

    if (!_cachedCharts[type].selectedBars.length) {
      _cachedCharts[type].bars = targetedChart;
    } else {
      _cachedCharts[type].bars = _cachedCharts[type].bars.map((bar, index) => {
        const selectedBar = _cachedCharts[type].selectedBars.find(selectedBar => {
          return selectedBar.label === bar.label;
        });
        bar.diffValue = targetedChart[index].value;
        if (selectedBar) {
          return {
            ...selectedBar,
            diffValue: null,
          };
        }
        bar.value = 0;
        return bar;
      });
    }

    if (type === CHART_TYPE.MOOD) {
      _cachedCharts[CHART_TYPE.CONFIDENCE].bars = __confChart;
      if (_cachedCharts[CHART_TYPE.MOOD].selectedBars.length === 0) {
        setMoodChart(_cachedCharts[CHART_TYPE.MOOD].bars);
      }
    }

    if (type === CHART_TYPE.CONFIDENCE) {
      _cachedCharts[CHART_TYPE.MOOD].bars = __moodChart;
      if (_cachedCharts[CHART_TYPE.CONFIDENCE].selectedBars.length === 0) {
        setConfChart(_cachedCharts[CHART_TYPE.CONFIDENCE].bars);
      }
    }

    const _reqPayload = { ...reqDataPayload, ...reqPayload };
    _reqPayload[type.toLowerCase()] = _cachedCharts[type].selectedBars.map(bar => bar.label);
    dispatch(setMcdDrillCachedCharts(_cachedCharts));
    dispatch(setMcdDrillDataPayload(_reqPayload));
    handleRequests(_reqPayload, skipRequests);

    if (type === CHART_TYPE.MOOD) {
      handleSilentReq(_reqPayload, CHART_TYPE.CONFIDENCE);
    }

    if (type === CHART_TYPE.CONFIDENCE) {
      handleSilentReq(_reqPayload, CHART_TYPE.MOOD);
    }
  };

  const handleConfidenceBarClick = (node, event) =>
    handleBarClick(event, node, CHART_TYPE.CONFIDENCE);
  const handleMoodBarClick = (node, event) => handleBarClick(event, node, CHART_TYPE.MOOD);

  return (
    <GeneralFiltersContainer pt={'pt-xxs'} param={'showInDrillToDetails'}>
      <DrillToDetailsStyled className="flex flex-col flex-1 overflow-auto px-xs pb-xs">
        <div className="page-title my-m">
          <EmwButton
            label="Back"
            variant="ghost"
            color="neutral"
            leftIcon="arrow_back_ios"
            onClick={handleBack}
          />
          <EmwTypography classes="text-primary-500 ml-xs" fontSize="5" fontWeight="bold">
            Mood & Confidence to deliver - Drill to details
          </EmwTypography>
          <div className="ml-auto">
            <DatePeriod />
          </div>
        </div>
        <div className="row" style={{ height: '317px', overflow: 'hidden' }}>
          <Grid container>
            <Grid item md={12} lg={6} className="pr-xs">
              <MoodAndDistribution
                chartData={moodChart}
                distData={moodDistribution}
                data={mood}
                words={moodWords}
                handleClick={handleClick}
                handleBarClick={handleMoodBarClick}
                chartType={chartType}
                filteredData={filteredMood}
                selectedBars={selectedBar}
                isFilteredData={isFilteredData || selectedRows.length}
                showWords={Boolean(selectedRows.length === 0)}
                hasNoDataMessage={hasNoDataMessage}
                isInFilteredMode={isInFilteredMode}
              />
            </Grid>
            <Grid item md={12} lg={6} className="pl-xs">
              <ConfidenceToDeliverDistribution
                chartData={confChart}
                distData={confDistribution}
                data={confidence}
                words={confidenceWords}
                handleClick={handleClick}
                handleBarClick={handleConfidenceBarClick}
                chartType={chartType}
                filteredData={filteredConfidence}
                selectedBars={selectedBar}
                isFilteredData={isFilteredData || selectedRows.length}
                showWords={Boolean(selectedRows.length === 0)}
                hasNoDataMessage={hasNoDataMessage}
                isInFilteredMode={isInFilteredMode}
              />
            </Grid>
          </Grid>
        </div>
        <ExportData />
        <div className="row  mt-m overflow-hidden flex-1" ref={tableWrapperRef}>
          <div className="flex-1 bg-white-100">
            <DetailsTable
              tableData={mcdData}
              tableWrapperRef={tableWrapperRef}
              setLoading={setLoadingState}
              isLoading={loadingState[DRILL_REQUESTS.MCD.DATA]}
            />
          </div>
        </div>
      </DrillToDetailsStyled>
    </GeneralFiltersContainer>
  );
}
