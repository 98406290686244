import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  QtmDropdown,
  QtmDropdownOverlay,
  QtmDropdownTrigger,
  QtmMenuItemLabel,
  QtmMenuItemList,
} from '@qtm/react';
import EmwIcon from '../../../../../../components/EmwIcon/EmwIcon';
import { computeURL, fetchOptions } from '../../../../../../../util/fetch';
import { DRILL_REQUESTS } from '../DrillToDetails/constants';
import { HzaContext } from '../../../../../hzaSync/context/HzaContext';
import { useSelector } from 'react-redux';
import {
  getDownloading,
  getMcdDrillDataPayload,
  getMcdDrillProcessData,
} from '../../../../redux/selectors';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import EmwProgressCircle from '../../../../../../components/EmwProgressCircle/EmwProgressCircle';
import useAppSnackbar from '../../../../../../hooks/useAppSnankbar';
import { useSnackbar } from 'notistack';
import ReportComplete from './ReportComplete';
import store from '../../../../../../../store';
import { MCD_DRILL_DATA_PAYLOAD, MCD_DRILL_TO_PAYLOAD } from '../../../../redux/actions';

export default function ExportData() {
  const hzaContext = useContext(HzaContext);
  const drillData = useSelector(getMcdDrillProcessData);
  const downloading = useSelector(getDownloading);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackbarKeyRef = useRef(null);
  const [isLoading, setIsLoading] = useState(hzaContext.downloadStatus.isLoading);

  useEffect(() => {
    setIsLoading(hzaContext.downloadStatus.isLoading);
  }, [hzaContext.downloadStatus.isLoading]);

  useEffect(() => {
    if (downloading) {
      showToast();
    } else {
      closeToast();
    }
  }, [downloading]);

  const handleDownload = () => {
    if (isLoading || downloading) {
      return;
    }
    const mcdDataApi = computeURL('mcd', DRILL_REQUESTS.MCD.DATA);
    const mcdDataParams = fetchOptions({ method: 'POST' });
    const _reqDataPayload = store.getState().powerBi[MCD_DRILL_DATA_PAYLOAD];
    const _reqPayload = store.getState().powerBi[MCD_DRILL_TO_PAYLOAD];
    const reqDataPayload = { ..._reqDataPayload, ..._reqPayload };
    reqDataPayload.rows = drillData.total;

    mcdDataParams.body = JSON.stringify(reqDataPayload);
    setIsLoading(true);

    hzaContext.downloadFile('mood_test', mcdDataApi, mcdDataParams, 'download_mcd_data');
  };

  const showToast = () => {
    const message = (
      <div className="flex flex-col">
        <div className="flex">
          <EmwProgressCircle size="small" color="neutral" />
          <EmwTypography fontWeight="bold" classes="ml-xs">
            Exporting data
          </EmwTypography>
        </div>
        <div>
          <EmwTypography>
            You can still browse reports and dashboard while we export your data
          </EmwTypography>
        </div>
      </div>
    );

    snackbarKeyRef.current = enqueueSnackbar(message, {
      variant: 'success',
      persist: true,
      dense: true,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      content: (key, message) => <ReportComplete id={key} message={message} />,
    });
  };

  const closeToast = () => {
    if (snackbarKeyRef.current) {
      closeSnackbar(snackbarKeyRef.current);
    }
  };

  return (
    <>
      <div className="relative">
        <div
          style={{
            position: 'absolute',
            cursor: 'pointer',
            right: 10,
            zIndex: 99,
            top: '28px',
            height: '18px',
            backgroundColor: 'transparent',
            '*': {
              zIndex: 99,
            },
          }}
        >
          <QtmDropdown size="small" disabled={isLoading || downloading}>
            <QtmDropdownTrigger>
              <div>
                <EmwIcon icon="more_vert" variant="outlined" style={{ color: 'white' }} />
              </div>
            </QtmDropdownTrigger>
            <QtmDropdownOverlay
              style={{ zIndex: 99, position: 'absolute', left: '-45px', top: '16px' }}
              open
              placement="bottom-right"
            >
              <QtmMenuItemList>
                <QtmMenuItemLabel onClick={handleDownload}>
                  <>
                    <EmwTypography fontSize={8}>Export data</EmwTypography>
                  </>
                </QtmMenuItemLabel>
              </QtmMenuItemList>
            </QtmDropdownOverlay>
          </QtmDropdown>
        </div>
      </div>
    </>
  );
}
