import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom-v5-compat';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import LandingPage from '../features/landingpage/components/LandingPage';
import Organization from '../features/organization/components/Organization';
import HiddenPage from './components/HiddenPage/HiddenPage';
import Home from './components/Home/Home';
import Administration from '../features/administration/components/Administration';
import { useIsAuthenticated } from '@azure/msal-react';
import { checkUserAccess } from '../auth/utils/MsGraphApiCall';
import LoggedOut from './components/LoggedOut/LoggedOut';
import RestrictedPage from './components/RestrictedPage/RestrictedPage';
import DataCollection from '../features/dataColection/DataColection';
import DrillContainer from '../features/powerBi/components/MoodAndConfidence/components/DrillToDetails/DrillContainer';

export const biPaths = [
  '/moodandconfidence/:report?',
  '/skills/:report?',
  '/jobfamily/:report?',
  '/engineeringcompetitivness/:report?',
];

export const biReportsToReact = [
  { path: 'moodandconfidence/drill-to-details', component: DrillContainer },
];

export default function Pages() {
  const isAuthenticated = useIsAuthenticated();
  const [hasAccess, setHasAccess] = useState(false);
  const [restrictedReason, setRestrictedReason] = useState();
  const isLoggedOut = localStorage.getItem('loggedOut');

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const restricted = await checkUserAccess();

        if (!restricted) {
          return setHasAccess(true);
        }

        let restrictedReason = '';

        if (restricted === 800) {
          restrictedReason =
            "You don't have rights to access application! Please contact your manager for rights!";
        }

        if (restricted === 801) {
          restrictedReason = 'All your rights are expired. You cannot access the application!';
        }

        if (restricted === 802) {
          restrictedReason = 'Unfortunately you are not identified in the EMW.';
        }

        setRestrictedReason(restrictedReason);
      })();
    }
  }, [isAuthenticated]);

  if (isAuthenticated && hasAccess) {
    return (
      <Routes>
        <Route path="/" element={<PrivateRoute component={Home} disableLayout={true} />} />
        <Route
          path="/administration/:page?"
          element={<PrivateRoute component={Administration} />}
        />
        <Route
          path="/data-collection-evolution"
          element={<PrivateRoute component={DataCollection} />}
        />
        <Route path="/landingpage/:id?" element={<PrivateRoute component={LandingPage} />} />
        <Route path="/organization" element={<PrivateRoute component={Organization} />} />
        {biPaths.map(path => (
          <Route path={path} key={path} element={<PrivateRoute component={HiddenPage} />} />
        ))}
        {biReportsToReact.map(reportPage => (
          <Route
            path={reportPage.path}
            key={reportPage.path}
            element={<PrivateRoute component={reportPage.component} />}
          />
        ))}
      </Routes>
    );
  }

  if (restrictedReason) {
    return <RestrictedPage message={restrictedReason} />;
  }

  if (!isAuthenticated && isLoggedOut) {
    return <LoggedOut />;
  }

  return null;
}
