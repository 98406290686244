import { styled } from '@mui/material';

export const StyledTableWrapper = styled('div')(({}) => ({
  tbody: {
    tr: {
      '&:hover': {
        opacity: '0.725',
      },
    },
    '.selected > td': {
      border: '1px solid white!important',
      backgroundColor: '#0000001f!important',
    },
  },
}));
