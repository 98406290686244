import React from 'react';
import useRequests from '../../../../useRequests';
import { useDispatch, useSelector } from 'react-redux';
import { getMcdDrillToPayload, getProjectsData } from '../../../../../../redux/selectors';
import { DRILL_REQUESTS } from '../../../DrillToDetails/constants';
import { setMcdDrillToPayload } from '../../../../../../redux/actions';
import SelectAllAutocomplete from './SelectAllAutocomplete';

export default function ProjectNamesSelector() {
  const { handleRequests } = useRequests();
  const dispatch = useDispatch();
  const reqPayload = useSelector(getMcdDrillToPayload);
  const projects = useSelector(getProjectsData);

  const handleChange = values => {
    const _reqPayload = { ...reqPayload };

    _reqPayload.projects = values;
    dispatch(setMcdDrillToPayload(_reqPayload));

    if (values.length) {
      return handleRequests(_reqPayload, [DRILL_REQUESTS.MCD.PROJECTS]);
    }
    return handleRequests(_reqPayload);
  };

  return <SelectAllAutocomplete options={projects} handleChange={handleChange} />;
}
