import useRequests from '../../../../useRequests';
import { useDispatch, useSelector } from 'react-redux';
import { getCountriesData, getMcdDrillToPayload } from '../../../../../../redux/selectors';
import React, { useState } from 'react';
import { setMcdDrillToPayload } from '../../../../../../redux/actions';
import { DRILL_REQUESTS } from '../../../DrillToDetails/constants';
import { Autocomplete, TextField } from '@mui/material';
import ListboxComponent from '../ListboxComponent/ListboxComponent';
import SelectAllAutocomplete from '../ProjectNamesSelector/SelectAllAutocomplete';

export default function CountryNameSelector() {
  const { handleRequests } = useRequests();
  const dispatch = useDispatch();
  const reqPayload = useSelector(getMcdDrillToPayload);
  const countries = useSelector(getCountriesData);

  const handleChange = values => {
    const _reqPayload = { ...reqPayload };

    _reqPayload.countries = values;
    dispatch(setMcdDrillToPayload(_reqPayload));
    if (values.length) {
      return handleRequests(_reqPayload, [DRILL_REQUESTS.MCD.COUNTRIES]);
    }

    return handleRequests(_reqPayload);
  };

  return <SelectAllAutocomplete options={countries} handleChange={handleChange} />;
}
