import { formatDateTimeForCompare, formatDateForCompare } from './format';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import store from '../store';
import { v4 as uuidv4 } from 'uuid';
import { USER_ACTIVITY_SERVICES } from '../V2/features/userActivity/constants';
import { DOWNLOADING } from '../V2/features/powerBi/redux/actions';

const sortDateAndTime = (a, b) => {
  const firstDate = new Date(...formatDateTimeForCompare(a)).getTime();
  const secondDate = new Date(...formatDateTimeForCompare(b)).getTime();
  if (firstDate < secondDate) {
    return 1;
  } else if (firstDate > secondDate) {
    return -1;
  } else {
    return 0;
  }
};

const sortDate = (a, b) => {
  const firstDate = new Date(...formatDateForCompare(a)).getTime();
  const secondDate = new Date(...formatDateForCompare(b)).getTime();
  if (firstDate < secondDate) {
    return 1;
  } else if (firstDate > secondDate) {
    return -1;
  }
  return 0;
};

const exportFromEdge = (fileName, blob) => {
  window.navigator.msSaveBlob(
    new Blob([blob], { type: 'data:application/vnd.ms-excel' }),
    fileName
  );
  return {};
};

const exportFile = (fileName, blob) => {
  if (window.navigator.userAgent.match(/Edge/)) {
    return exportFromEdge(fileName, blob);
  }
  if (blob.byteLength < 1000) {
    return JSON.parse(new TextDecoder('utf-8').decode(blob));
  }
  const newBlob = new Blob([blob], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 500);
  return {};
};

const alphaStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const exportJsonToExcel = async (fileName, data) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');
  const headers = Object.keys(data[0]).map(key => transformHeader(key));

  worksheet.addRow(headers);
  styleColumns(worksheet, headers.length);

  data.forEach(item => {
    worksheet.addRow(Object.values(item));
  });

  headers.forEach((header, index) => {
    worksheet.getColumn(index + 1).width = header.length + 25;
  });

  try {
    await download(workbook, fileName);
  } catch (err) {
    console.error('Error downloading file: ', err);
  }
};

const transformHeader = key => {
  return key.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase();
};

const styleColumns = (worksheet, columnCount) => {
  worksheet.columns.forEach((column, i) => {
    if (i < columnCount) {
      const cellName = `${alphaStr[i]}1`;
      const cell = worksheet.getCell(cellName);
      cell.font = {
        color: { argb: 'DEEAF5' },
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '5B9BD5' },
      };
      cell.border = {
        top: { style: 'double', color: { argb: 'FFFFFF' } },
        left: { style: 'double', color: { argb: 'FFFFFF' } },
        bottom: { style: 'double', color: { argb: 'FFFFFF' } },
        right: { style: 'double', color: { argb: 'FFFFFF' } },
      };
    }
  });
};

const download = async (workbook, fileName) => {
  setTimeout(async () => {
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  }, 100);
};

const arrayToXcel = async (fileName, data) => {
  return exportToExcel(data);
};

const exportToExcel = async data => {
  store.dispatch({
    type: 'DOWNLOADING',
    payload: true,
  });
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');

  worksheet.columns = data.headers;

  data.rows.forEach(item => worksheet.addRow(item));

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  setTimeout(() => {
    console.log('start');
    saveAs(blob, 'ExportedData.xlsx');
    console.log('done');
    store.dispatch({
      type: 'DOWNLOADING',
      payload: false,
    });
  }, 1);
};

export { sortDateAndTime, sortDate, exportFile, exportJsonToExcel, arrayToXcel };
